"use client";

import { useRouter, usePathname } from "next/navigation";
import { useEffect } from "react";
const MAINTENANCE_PAGE = "/maintenance";
const INTERVAL_MS_MAINTENANCE = 60000;
const isOnMaintenancePage = (pathname: string) => pathname === MAINTENANCE_PAGE;
const handleHealthcheckResponse = (response: Response, pathname: string, router: ReturnType<typeof useRouter>) => {
  if (response.status === 200 && isOnMaintenancePage(pathname)) {
    router.push("/");
  } else if (response.status !== 200) {
    console.error("Server is still in maintenance or there is an issue with the healthcheck route. Status:", response.status);
  }
};
const handleHealthcheckError = (error: unknown) => {
  console.error("Healthcheck request failed. The server might be unavailable or the healthcheck route is unreachable.", error);
};
const MaintenanceWatcher = () => {
  const router = useRouter();
  const pathname = usePathname();
  useEffect(() => {
    if (isOnMaintenancePage(pathname)) {
      const checkSiteStatus = async () => {
        try {
          const response = await fetch(`${process.env.NEXT_PUBLIC_CLUB_API_URL}/system/healthcheck`);
          handleHealthcheckResponse(response, pathname, router);
        } catch (error) {
          handleHealthcheckError(error);
        }
      };
      const interval = setInterval(() => {
        void checkSiteStatus();
      }, INTERVAL_MS_MAINTENANCE);
      return () => clearInterval(interval);
    }
  }, [router, pathname]);
  return null;
};
export default MaintenanceWatcher;