"use client";

import LogRocket from "logrocket";
import { useEffect } from "react";
const LogRocketInitializer = () => {
  const isDevelopment = process.env.NODE_ENV === "development";
  const isLocalTesting = process.env.NEXT_PUBLIC_SENTRY_LOCAL_TESTING === "true";
  const appId = process.env.NEXT_PUBLIC_LOGROCKET_ID;
  useEffect(() => {
    if (!appId) {
      console.error("LogRocket App ID not defined");
      return;
    }
    if (isDevelopment && !isLocalTesting) {
      console.log("LogRocket disabled (dev mode without local testing)");
      return;
    }
    if (isDevelopment && isLocalTesting) {
      console.log("LogRocket enabled (dev mode with local testing)");
    }
    try {
      LogRocket.init(appId);
    } catch (error) {
      console.error("Error during LogRocket initialization:", error);
    }
  }, [appId, isDevelopment, isLocalTesting]);
  return null;
};
export default LogRocketInitializer;