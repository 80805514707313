"use client";

import React, { createContext, useState } from "react";
import { ClubContextValue, ClubFormatProps } from "@/TS/Interfaces/global_interfaces";
export const ClubContext = createContext<ClubContextValue>(undefined);
function ClubProvider({
  children
}) {
  const [format, setFormat] = useState<ClubFormatProps>(undefined);
  return <ClubContext.Provider value={{
    format,
    setFormat
  }} data-sentry-element="unknown" data-sentry-component="ClubProvider" data-sentry-source-file="club-provider.tsx">
      {children}
    </ClubContext.Provider>;
}
export default ClubProvider;