"use client";

import React, { useContext, useState } from "react";
import { dropdownModalContext } from "@/app/(main)/(club)/mes-clubs/[id]/leads/context/dropdown-modal-provider";
import useMobile from "@/hooks/use-mobile";
function DropdownModal() {
  const {
    dropData
  } = useContext(dropdownModalContext);
  const isMobile = useMobile();
  React.useEffect(() => {
    const handler = event => {
      if (!dropData?.modalRef?.current) {
        return;
      }
      if (event.target) {
        if (!dropData.modalRef.current.contains(event.target)) {
          dropData.setIsOpen(false);
        }
      }
    };
    document.addEventListener("click", handler, {
      capture: true
    });
    return () => {
      document.removeEventListener("click", handler, {
        capture: true
      });
    };
  }, [dropData, dropData?.setIsOpen]);
  const handleClick = event => {
    event.stopPropagation();
  };
  if (!dropData?.coords || !dropData?.isOpen) return;
  if (!isMobile) {
    return <div ref={dropData.modalRef} onClick={handleClick} style={{
      top: `${dropData?.coords.offsetTop}px`,
      left: `${dropData?.coords.offsetLeft}px`,
      transform: (() => {
        const baseTranslateY = `translateY(${dropData?.coords.h + 10}px)`;
        const translateX = `translateX(calc(-100% + ${dropData?.coords.w}px))`;
        const translateY = `translateY(-100%)`;
        if (dropData?.isOut || dropData?.isOutBottom) {
          return `${baseTranslateY} ${translateX} ${dropData?.isOutBottom && translateY}`;
        }
        return baseTranslateY + " " + translateX;
      })()
    }} className="absolute flex z-[99] flex-col text-nowrap flex-wrap w-fit bg-white dropdown-shadow rounded-lg py-3">
        {dropData.form ? <dropData.form>{dropData.jsx}</dropData.form> : dropData.jsx}
      </div>;
  } else {
    return <div onClick={handleClick} ref={dropData.modalRef} className="fixed flex z-[99] left-0 flex-col text-nowrap flex-wrap w-full bottom-0 bg-white dropdown-shadow rounded-t-lg py-6">
        {dropData.form ? <dropData.form>{dropData.jsx}</dropData.form> : dropData.jsx}
      </div>;
  }
}
export default DropdownModal;