"use client";

import { ReactNode, useContext, useEffect, useRef } from "react";
import DropdownModal from "@/app/(main)/(club)/mes-clubs/[id]/leads/components/dropdown-modal";
import UserSidebar from "@/app/(main)/_user_components/user-sidebar";
import Icon from "@/components/icon";
import { sidebarContext } from "@/context/sidebar-context";
import handlePanelJsx from "@/hooks/handle-panel-jsx";
import useMobile from "@/hooks/use-mobile";
import useSidebar from "@/hooks/use-sidebar";
import { RightPanelProps } from "@/TS/Interfaces/global_interfaces";
export default function RightPanel(props: RightPanelProps) {
  const {
    val,
    setVal,
    isHide,
    handleJsx,
    setPanelRef
  } = useContext(sidebarContext);
  const panelRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (!panelRef) return;
    setPanelRef(panelRef);
    panelRef.current.scrollTop = 0;
  }, [val]);
  const isMobile = useMobile();
  return <div className={`fixed right-0 top-0 w-full h-full z-[1002] ${!val ? "hidden" : ""}`} data-sentry-component="RightPanel" data-sentry-source-file="right-panel.tsx">
      <div className={"w-full h-full absolute bg-secondary opacity-50"} onClick={() => setVal("")}></div>
      {!isHide && <div className={`relative z-50 ml-auto bg-white w-full h-full flex flex-col justify-between ${UserSidebar !== val?.type ? "md:w-4/5" : "md:w-max"}`}>
          {UserSidebar !== val?.type &&
      //permet de masquer "Retour" sur la UserSidebar le temps de refacto la partie isHide //Victor
      <div className={"flex h-[60px] bg-primary"}>
              <div className={"cursor-pointer text-white p-2 flex items-center"} onClick={() => handleJsx()}>
                <Icon icon={"left-arrow"} size={20} className={"mr-2 ml-2"} />
                <p>Retour</p>
              </div>
            </div>}
          <div ref={panelRef} className={`${val?.props?.mobileSide ? "" : "gap-6 p-6 2xl:p-2"} grow overflow-y-auto relative ${UserSidebar !== val?.type ? "md:p-16 md:px-28 2xl:px-56" : ""}`}>
            {val !== "" && !isHide && <DropdownModal></DropdownModal>}
            {val !== "" && !isHide && val}
          </div>
        </div>}
      {val !== "" && isHide && val}
    </div>;
}